<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { required, decimal, maxLength, requiredIf } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouveau Contrat",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Nouveau contrat",
      suppliers: [],
      vrules: {
        contractRef: {
          maxLength: 64,
        }
      },
      contractForm: {
        contractRef: "",
        contractSupplier: "",
        contractType: "",
        contractPeriodFrom: "",
        contractPeriodTo: "",
        contractAmnt: "",
        contractComments: "",
        // catering contract
        contractAllocatedHostingAmmount: 0,
        contractAllocatedRestaurationAmmount: 0,
        contractAllocatedMaintenanceAmmount: 0,
        contractAllocatedEscpaceVertAmmount: 0,
        contractAllocatedOperations3DAmmount: 0,
        contractAllocatedDiversesAmmount: 0,
        contractAllocatedNettoyageAmmount: 0,
        contractAllocatedBlanchisserieAmmount: 0,
        contractAllocatedTransportPersonnelAmmount: 0,
        contractAllocatedTransportMarchandiseAmmount: 0,
        // gardenig contract
        contractPesticidesAmountAllocated: "",
        contractConstructionsAmountAllocated: "",
        contractRollingEquipmentsAmountAllocated: "",
        contractMaintenanceAmountAllocated: "",
        // other contract
        contractSignatureDate: "",
        contractEffectiveDate: "",
        hebergement : "",
        restauration : "",
        maintenance : "",
        escpaceVert : "",
        operations3D : "",
        diverses : "",
        nettoyage: "",
        blanchisserie: "",
        transportPersonnel: "",
        transportMarchandise: "",
      },
      types: [/* 
        {
          id: "catering",
          name: "Full Catering",
        },

        {
          id: "Maintenance",
          name: "Maintenance",
        },

        {
          id: "gardening",
          name: "Espace Vert",
        },
       */],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Contrats",
          href: "/",
        },
        {
          text: "Nouveau contrat",
          active: true,
        },
      ],
    };
  },

  validations: {
    contractForm: {
      contractRef: {
        required,
        maxLength: maxLength(64),
      },
      contractSupplier: { required },
      contractType: { required },
      contractPeriodFrom: { required },
      contractPeriodTo: { required },
      contractAmnt: { required, decimal },
      contractAllocatedHostingAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering"
        }),
        
      },
      contractAllocatedCateringAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering"
        })
      },
      contractAllocated3dAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering"
        })
      },
    },
  },

  created() {
    this.fetchContratTypeList();
    this.fetchSuppliersList();
  },

  methods: {
    calculateTotal(){
        var totalAmmount = parseInt(this.contractForm.contractAmnt);
        var HostingAmmount = parseInt(this.contractForm.contractAllocatedHostingAmmount);
        var RestaurationAmmount = parseInt(this.contractForm.contractAllocatedRestaurationAmmount);
        var MaintenanceAmmount = parseInt(this.contractForm.contractAllocatedMaintenanceAmmount);
        var EscpaceVertAmmount = parseInt(this.contractForm.contractAllocatedEscpaceVertAmmount);
        var Operations3DAmmount = parseInt(this.contractForm.contractAllocatedOperations3DAmmount);
        var DiversesAmmount = parseInt(this.contractForm.contractAllocatedDiversesAmmount);
        var total = HostingAmmount + RestaurationAmmount + MaintenanceAmmount + EscpaceVertAmmount + Operations3DAmmount + DiversesAmmount;
        if(totalAmmount < total) {
          Swal.fire("Avertissement!", "total should be under ", "warning");
        }
    },
    fetchSuppliersList() {
      this.$http
        .post("/contracts/suppliers/list")
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchContratTypeList() {
      this.$http
        .post("/base/contratType/list")
        .then((res) => (this.types = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    _validateContractAmnts(){
      var validated = true;
      // var contractType = this.contractForm.contractType;

      return validated;

    },
    formSubmit() {
      this.submitted = true;
      var valid = true;
      // var validFormData = this._validateContractAmnts();
      var router = this.$router;
      if(valid){

      this.$http
        .post("/contracts/contracts/store", this.contractForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              setTimeout(function() {
                router.push({ name: "base.contracts.contracts.index" });
              }, 3000);
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
      }

    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Référence *</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractRef"
                      type="text"
                      class="form-control"
                      @blur="$v.contractForm.contractRef.$touch"
                      :class="{ 'is-invalid': submitted && $v.contractForm.contractRef.$error, }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractRef.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractRef.required">La référence du contrat est obligatoire.</span> <br>
                      <span v-if="!$v.contractForm.contractRef.maxLength">La taille minimum du champs est: <b>{{ this.vrules.contractRef.maxLength }}</b> Caractères.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-password-input">Prestataire *</label>
                    <select
                      id="projectname"
                      v-model="contractForm.contractSupplier"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.contractForm.contractSupplier.$error, }"
                      placeholder=""
                    >
                      <option value=""></option>
                      <option
                        v-for="supplier in suppliers"
                        v-bind:value="supplier.id"
                        v-bind:key="supplier.id"
                      >
                        {{ supplier.name }}
                      </option>
                    </select>
                    <div v-if="submitted && $v.contractForm.contractSupplier.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractSupplier.required">Le prestataire est obligatoire.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Type *</label>
                    <select
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractType.$error,
                      }"
                      v-model="contractForm.contractType"
                    >
                      <option value=""></option>
                      <option
                        v-for="type in types"
                        v-bind:value="type.id"
                        v-bind:key="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                    <div
                      v-if="
                        submitted && $v.contractForm.contractType.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractForm.contractType.required"
                        >Le Type est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <label for="formrow-inputState"
                      >Montant total (en DZD) *</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractAmnt"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAmnt.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.contractForm.contractAmnt.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractForm.contractAmnt.required">La montant total du contrat est obligatoire.</span>
                      <span v-if="!$v.contractForm.contractAmnt.decimal">La montant est invalide.</span>
                     
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2 ">
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.hebergement"
                      id="hebergement"
                    />
                    <label class="form-check-label" for="hebergement">
                      Hébergement
                    </label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.restauration"
                      id="restauration"
                    />
                    <label class="form-check-label" for="restauration">
                      Restauration
                    </label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.maintenance"
                      id="maintenance"
                    />
                    <label class="form-check-label" for="maintenance">
                      Maintenance
                    </label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.escpaceVert"
                      id="escpaceVert"
                    />
                    <label class="form-check-label" for="escpaceVert">
                      Escpace Vert
                    </label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.operations3D"
                      id="operations3D"
                    />
                    <label class="form-check-label" for="operations3D">
                      Opérations 3D
                    </label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.diverses"
                      id="diverses"
                    />
                    <label class="form-check-label" for="diverses">
                      Diverses
                    </label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.nettoyage"
                      id="Nettoyage"
                    />
                    <label class="form-check-label" for="Nettoyage">
                      Nettoyage
                    </label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.blanchisserie"
                      id="blanchisserie"
                    />
                    <label class="form-check-label" for="blanchisserie">
                      Blanchisserie
                    </label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.transportPersonnel"
                      id="transportPersonnel"
                    />
                    <label class="form-check-label" for="transportPersonnel">
                      Transport Personnel
                    </label>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="contractForm.transportMarchandise"
                      id="transportMarchandise"
                    />
                    <label class="form-check-label" for="transportMarchandise">
                      Transport Marchandise
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4" v-if="contractForm.hebergement">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Montant alloué Hébergemet</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedHostingAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedHostingAmmount.$error,
                      }"
                      placeholder=""
                    />
                  <div v-if="submitted && $v.contractForm.contractAllocatedHostingAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedHostingAmmount.required">Le Montant alloué Hébergemet est obligatoire.</span>
                  </div>
                  </div>
                </div>

                <div class="col-md-4"  v-if="contractForm.restauration">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Montant alloué Restauration</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedRestaurationAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedRestaurationAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedRestaurationAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedRestaurationAmmount.required">Le Montant alloué Restauration est obligatoire.</span>
                    </div>
                  </div>

                  
                </div>

                <div class="col-md-4"  v-if="contractForm.maintenance">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant Maintenance</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedMaintenanceAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                       :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedMaintenanceAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedMaintenanceAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedMaintenanceAmmount.required">Le Montant alloué 3D est obligatoire.</span>
                    </div>
                   
                  </div>
                </div>

                <div class="col-md-4"  v-if="contractForm.escpaceVert">
                  <div class="form-group">
                    <label for="formrow-email-input">Escpace Vert</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedEscpaceVertAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                       :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedEscpaceVertAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedEscpaceVertAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedEscpaceVertAmmount.required">Le Montant alloué 3D est obligatoire.</span>
                    </div>
                   
                  </div>
                </div>

                <div class="col-md-4"  v-if="contractForm.operations3D">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant Opérations 3D</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedOperations3DAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                       :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedOperations3DAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedOperations3DAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedOperations3DAmmount.required">Le Montant alloué Escpace Vert est obligatoire.</span>
                    </div>
                   
                  </div>
                </div>

                <div class="col-md-4"  v-if="contractForm.diverses">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant Diverses</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedDiversesAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                       :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedDiversesAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedDiversesAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedDiversesAmmount.required">Le Montant alloué Diverses est obligatoire.</span>
                    </div>
                   
                  </div>
                </div>
                <div class="col-md-4"  v-if="contractForm.nettoyage">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant Nettoyage</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedNettoyageAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                       :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedNettoyageAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedNettoyageAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedNettoyageAmmount.required">Le Montant alloué Nettoyage est obligatoire.</span>
                    </div>
                   
                  </div>
                </div>
                <div class="col-md-4"  v-if="contractForm.blanchisserie">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant Blanchisserie</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedBlanchisserieAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                       :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedBlanchisserieAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedBlanchisserieAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedBlanchisserieAmmount.required">Le Montant alloué Blanchisserie est obligatoire.</span>
                    </div>
                   
                  </div>
                </div>
                <div class="col-md-4"  v-if="contractForm.transportPersonnel">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant Transport Personnel</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedTransportPersonnelAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                       :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedTransportPersonnelAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedTransportPersonnelAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedTransportPersonnelAmmount.required">Le Montant alloué Transport Personnel est obligatoire.</span>
                    </div>
                   
                  </div>
                </div>
                <div class="col-md-4"  v-if="contractForm.transportMarchandise">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant Transport Marchandise</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedTransportMarchandiseAmmount"
                      @input="calculateTotal"
                      type="text"
                      class="form-control"
                       :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedTransportMarchandiseAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedTransportMarchandiseAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedTransportMarchandiseAmmount.required">Le Montant alloué Transport Marchandise est obligatoire.</span>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div class="row" v-if="contractForm.contractType === 'gardening'">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Montant Entretien</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractMaintenanceAmountAllocated"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractMaintenanceAmountAllocated
                            .$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.contractForm.contractMaintenanceAmountAllocated
                            .$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.contractForm.contractMaintenanceAmountAllocated
                            .required
                        "
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Fournitures de produits Phytosanitaires</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractPesticidesAmountAllocated"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractPesticidesAmountAllocated
                            .$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.contractForm.contractPesticidesAmountAllocated
                            .$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.contractForm.contractPesticidesAmountAllocated
                            .required
                        "
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Montant Réalisation</label
                    >
                    <input
                      id="projectname"
                      v-model="
                        contractForm.contractConstructionsAmountAllocated
                      "
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractConstructionsAmountAllocated
                            .$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.contractForm.contractConstructionsAmountAllocated
                            .$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.contractForm.contractConstructionsAmountAllocated
                            .required
                        "
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant Matériel roulant</label>
                    <input
                      id="projectname"
                      v-model="
                        contractForm.contractRollingEquipmentsAmountAllocated
                      "
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm
                            .contractRollingEquipmentsAmountAllocated.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.contractForm
                            .contractRollingEquipmentsAmountAllocated.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.contractForm
                            .contractRollingEquipmentsAmountAllocated.required
                        "
                        >La montant 3D est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputCity"
                      >Date début de validité *</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractPeriodFrom"
                      type="date"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractPeriodFrom.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted && $v.contractForm.contractPeriodFrom.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractForm.contractPeriodFrom.required"
                        >La date début du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputState">Date d'expiration *</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractPeriodTo"
                      type="date"
                      class="form-control"
                      placeholder=""
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractPeriodTo.$error,
                      }"
                    />
                    <div
                      v-if="
                        submitted && $v.contractForm.contractPeriodTo.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractForm.contractPeriodTo.required"
                        >La date fin du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputCity">Date de signature</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractSignatureDate"
                      type="date"
                      class="form-control"
                      placeholder=""
                    />
                    
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputState"
                      >Date d’entrée en vigueur</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractEffectiveDate"
                      type="date"
                      class="form-control"
                      placeholder=""
                    />
                    
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
